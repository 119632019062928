import color from "@definitions/mui/color";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: "none",
    border: "1px solid #B3C3CA",
    fontSize: 14,
    marginTop: 8,
  },
  select: {
    display: "flex",
    alignItems: "center",
    width: 18,
    marginLeft: 8,
  },
  root: {
    display: "flex",
    alignItems: "center",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0",
    },
  },
  icon: {
    color: color.general.main,
    top: "calc(50% - 12px)",
  },
}));

export default useStyles;
