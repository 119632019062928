import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SVGIconGlobe = (props: IconProps) => {
  return (
    <SvgIcon {...props} color="inherit" viewBox="0 0 21 20">
      <path d="M10.7673 0C5.24734 0 0.777344 4.48 0.777344 10C0.777344 15.52 5.24734 20 10.7673 20C16.2973 20 20.7773 15.52 20.7773 10C20.7773 4.48 16.2973 0 10.7673 0ZM17.6973 6H14.7473C14.4273 4.75 13.9673 3.55 13.3673 2.44C15.2073 3.07 16.7373 4.35 17.6973 6ZM10.7773 2.04C11.6073 3.24 12.2573 4.57 12.6873 6H8.86734C9.29734 4.57 9.94734 3.24 10.7773 2.04ZM3.03734 12C2.87734 11.36 2.77734 10.69 2.77734 10C2.77734 9.31 2.87734 8.64 3.03734 8H6.41734C6.33734 8.66 6.27734 9.32 6.27734 10C6.27734 10.68 6.33734 11.34 6.41734 12H3.03734ZM3.85734 14H6.80734C7.12734 15.25 7.58734 16.45 8.18734 17.56C6.34734 16.93 4.81734 15.66 3.85734 14ZM6.80734 6H3.85734C4.81734 4.34 6.34734 3.07 8.18734 2.44C7.58734 3.55 7.12734 4.75 6.80734 6ZM10.7773 17.96C9.94734 16.76 9.29734 15.43 8.86734 14H12.6873C12.2573 15.43 11.6073 16.76 10.7773 17.96ZM13.1173 12H8.43734C8.34734 11.34 8.27734 10.68 8.27734 10C8.27734 9.32 8.34734 8.65 8.43734 8H13.1173C13.2073 8.65 13.2773 9.32 13.2773 10C13.2773 10.68 13.2073 11.34 13.1173 12ZM13.3673 17.56C13.9673 16.45 14.4273 15.25 14.7473 14H17.6973C16.7373 15.65 15.2073 16.93 13.3673 17.56ZM15.1373 12C15.2173 11.34 15.2773 10.68 15.2773 10C15.2773 9.32 15.2173 8.66 15.1373 8H18.5173C18.6773 8.64 18.7773 9.31 18.7773 10C18.7773 10.69 18.6773 11.36 18.5173 12H15.1373Z" fill="#B1B8BC"/>
    </SvgIcon>
  );
};

export default SVGIconGlobe;
