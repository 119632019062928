/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from "next/router";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Text from "@ds/components/Text";
import SVGIconGlobe from "@icons/IconGlobe";
import useStyles from "./styles";

type TSelectLanguage = {
  value: string;
  onChange: any;
};

const CompSelectLanguage: React.FC<TSelectLanguage> = ({ value, onChange }) => {
  const classes = useStyles();
  const router = useRouter();

  const _renderSelectedLabel = (value: string) => {
    const appLangID = value === "id" && router?.locale === 'id';
    return (
      <>
        {appLangID ? (
          <Text color="general-main" weight="700">
            ID
          </Text>
        ) : (
          <Text color="general-main" weight="700">
            EN
          </Text>
        )}
      </>
    );
  };

  return (
    <FormControl
      fullWidth
      css={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        border: "none",
      }}
    >
      <SVGIconGlobe css={{ width: 16, height: 16 }} />
      <Select
        data-testid="click-select"
        sx={{ border: "none" }}
        classes={{
          select: classes.select,
          /**
           * TODO: temporary hide, because 'root' does not exist in Partial<SelectClasses>
           */
          // root: classes.root,
          icon: classes.icon,
        }}
        disableUnderline
        inputProps={{
          name: "select-language",
          id: "language-controlled",
        }}
        onChange={onChange}
        value={value && router?.locale}
        MenuProps={{
          classes: {
            paper: classes.paper,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        variant="standard"
        IconComponent={KeyboardArrowDownRoundedIcon}
        renderValue={() => _renderSelectedLabel(value)}
      >
        <MenuItem aria-label="id" value="id" sx={{ fontSize: 16 }}>
          Indonesia (ID)
        </MenuItem>
        <MenuItem aria-label="en" value="en" sx={{ fontSize: 16 }}>
          English (EN)
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CompSelectLanguage;
